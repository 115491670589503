<i18n>
{
  "de": {
    "displayBuildingCount": "{total} Liegenschaft | {total} Liegenschaften",
    "displayFilteredBuildingCount": "{filtered} von {total} Liegenschaften",
    "pendingMessage": "Die Liegenschaften werden gelöscht, dieser Vorgang kann einen Moment dauern, bitte warten Sie.",
    "noBuildingsFound": "Dieses Portfolio ist noch leer.",
    "noBuildingsMatchQuery": "Keine Treffer für diesen Filter.",
    "addBuildingButton": "Liegenschaft hinzufügen",
    "sortReverseButton": "Sortierung umkehren",
    "exportPortfolioButton": "Portfolio export (CSV)",
    "deleteConfirmation": "Wollen Sie alle Liegenschaften löschen?",
    "removeBuildingsButton": "Alle Liegenschaften löschen",
    "importBuilding": "Liegenschaft importieren",
    "table" :{
      "id": "Identifikation",
      "description": "Bezeichnung",
      "location": "Standort",
      "area": "Fläche (EBF)",
      "energy": "Endenergie",
      "emissions": "Scope 1-2",
      "usage": "Hauptnutzung",
      "heating": "Heizung",
      "canton": "Kanton",
      "yearBuilt": "Baujahr",
      "buildingComponents": "Bauteile"
    }
  }
}
</i18n>

<template>
  <div v-if="error">
    {{ error }}
  </div>
  <div v-else-if="pending">
    {{ $t('pendingMessage') }}
  </div>
  <div v-else class="c-portfolio-list">
    <!-- Controls -->
    <div class="portfolio-controls">
      <div class="portfolio-controls__count">
        <!-- TODO: Select -->
        <!--  -->
        <!-- Counts -->
        <ClipLoader v-if="buildingsLoading" size="18px" color="#000" />
        <div v-else class="portfolio-summary">
          <span>{{ numberOfBuildingsText }}</span>
          <div class="portfolio-summary-info-box">
            <SummaryInfoBox v-if="!buildingsLoading" :summary-data="portfolioSummary" filterable />
          </div>
        </div>
      </div>

      <!-- Sorting and others -->
      <div class="portfolio-controls__sorting">
        <SortDropdown :portfolio="portfolio" />

        <VPopover trigger="hover">
          <SortReverse :portfolio="portfolio" />
          <template slot="popover">
            <main>{{ $t('sortReverseButton') }}</main>
          </template>
        </VPopover>

        <VPopover trigger="hover">
          <button
            type="button"
            class="button button--square"
            :disabled="exportLoading"
            @click="exportPortfolioBuildings(portfolio.id)"
          >
            <img v-if="!exportLoading" class="icon" src="/icons/download.svg" />
            <ClipLoader v-else class="export-spinner" size="18px" color="#000" />
          </button>
          <template slot="popover">
            <main>{{ $t('exportPortfolioButton') }}</main>
          </template>
        </VPopover>

        <template v-if="getPortfolioPermission('EDIT_BUILDINGS')">
          <VPopover trigger="hover">
            <router-link
              class="button button--square"
              :to="{ name: 'addBuilding', params: { portfolio_id: portfolio.id } }"
            >
              <img class="icon" src="/icons/plus.svg" />
            </router-link>
            <template slot="popover">
              <main>{{ $t('addBuildingButton') }}</main>
            </template>
          </VPopover>
        </template>

        <!-- Cards vs table toggle -->
        <nav class="toggle-buttons">
          <button
            type="button"
            class="icon"
            :class="{ selected: displayMode === 'cards' }"
            @click="setDisplayMode('cards')"
          >
            <div>
              <img src="/icons/grid.svg" />
            </div>
          </button>
          <button
            type="button"
            class="icon"
            :class="{ selected: displayMode === 'table' }"
            @click="setDisplayMode('table')"
          >
            <div>
              <img src="/icons/table.svg" />
            </div>
          </button>
        </nav>
      </div>
    </div>

    <!-- Buildings list  -->
    <ul
      v-if="buildings && buildings.length"
      class="portfolio-list"
      :class="{ 'portfolio-list--table': displayMode === 'table' }"
    >
      <!-- Table header -->
      <div v-if="displayMode === 'table'" class="portfolio-list__table-header">
        <div class="portfolio-list-item-queue">
          <HeaderEntry :title="$t('table.id')" />
        </div>
        <div class="building-description">
          <HeaderEntry :title="$t('table.description')" class="address" />
          <HeaderEntry :title="$t('table.location')" />
        </div>
        <div class="footer">
          <div class="building-kpis">
            <HeaderEntry :title="$t('table.area')" :unit="'m²'" class="energy-area" align-right />
            <HeaderEntry :title="$t('table.energy')" :unit="'kWh/m²'" align-right />
            <HeaderEntry :title="$t('table.emissions')" :unit="'kg CO₂e/m²'" align-right />
          </div>
          <div class="building-attributes">
            <HeaderEntry :title="$t('table.usage')" class="usage" />
            <HeaderEntry :title="$t('table.heating')" class="heating-type" />
            <HeaderEntry :title="$t('table.canton')" class="canton" />
            <HeaderEntry :title="$t('table.yearBuilt')" class="year-built" />
          </div>
        </div>
        <div class="rings">
          <HeaderEntry :title="$t('table.buildingComponents')" />
        </div>
      </div>

      <!-- Items -->
      <PortfolioListItem
        v-for="building in buildings"
        :key="building.id"
        :portfolio="portfolio"
        :building="building"
        :search-text="searchText"
        :display-mode="displayMode"
      />
    </ul>

    <div v-if="!buildingsLoading && totalNumberOfBuildings === 0">
      <div>{{ $t('noBuildingsFound') }}</div>
    </div>

    <div v-else-if="!buildingsLoading && filteredNumberOfBuildings === 0">
      <div>{{ $t('noBuildingsMatchQuery') }}</div>
    </div>

    <!-- Buttons -->
    <ButtonWrapper v-if="getPortfolioPermission('EDIT_BUILDINGS')">
      <router-link class="button" :to="{ name: 'addBuilding', params: { portfolio_id: portfolio.id } }">
        <img src="/icons/plus.svg" :alt="$t('addBuildingButton')" class="icon" />
        <span>
          {{ $t('addBuildingButton') }}
        </span>
      </router-link>
      <button type="button" class="button" @click="onImportBuilding">{{ $t('importBuilding') }}</button>
      <ImportBuildingModal v-if="importModalOpen" :portfolio="portfolio" @close="onImportBuildingClose" />
      <Button
        v-if="buildings.length > 0"
        :text="$t('removeBuildingsButton')"
        icon="trash"
        class="button--delete"
        @click="onDeleteAllBuildings"
      >
      </Button>
    </ButtonWrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import _ from 'lodash'

import { VPopover } from 'v-tooltip'

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import Button from '@/components/cui/inputs/Button.vue'
import ImportBuildingModal from '@/components/portfolio/ImportBuildingModal.vue'
import PortfolioListItem from '@/components/portfolio/PortfolioListItem.vue'
import SortDropdown from '@/components/portfolio/SortDropdown.vue'
import SortReverse from '@/components/portfolio/SortReverse.vue'
import SummaryInfoBox from '@/components/portfolio/SummaryInfoBox.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'

export default {
  components: {
    Button,
    ButtonWrapper,
    PortfolioListItem,
    SortDropdown,
    SortReverse,
    ImportBuildingModal,
    VPopover,
    ClipLoader,
    SummaryInfoBox,
    HeaderEntry,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    searchText: {
      type: String,
      required: false,
    },
    asTable: {
      type: Boolean,
      default: false,
    },
    buildings: {
      type: Array,
      required: true,
    },
    buildingsLoading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      pending: false,
      importModalOpen: false,
    }
  },

  computed: {
    ...mapState('portfolio', ['defaultSustainabilityIndicatorIdentifiers', 'exportLoading']),
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    filteredNumberOfBuildings() {
      return this.buildings.length
    },

    totalNumberOfBuildings() {
      return this.portfolio.summary.total || 0
    },

    portfolioSummary() {
      const portfolioStatusCounts = this.portfolio.summary.status_counts || {}
      const filteredStatusCounts = _(this.buildings || [])
        .countBy('status')
        .mapKeys((value, key) => key.toLowerCase())
        .value()

      return {
        total: [this.filteredNumberOfBuildings, this.totalNumberOfBuildings],
        status_counts: {
          planner: [filteredStatusCounts.planner || 0, portfolioStatusCounts.planner || 0],
          reporter: [filteredStatusCounts.reporter || 0, portfolioStatusCounts.reporter || 0],
          archived: [filteredStatusCounts.archived || 0, portfolioStatusCounts.archived || 0],
        },
      }
    },

    numberOfBuildingsText() {
      const filtered = this.filteredNumberOfBuildings
      const total = this.totalNumberOfBuildings

      return total === filtered
        ? this.$tc('displayBuildingCount', total, { total })
        : this.$t('displayFilteredBuildingCount', { filtered, total })
    },

    displayMode() {
      return this.$store.state.ui.portfolioListDisplayMode
    },
  },

  created() {
    const mode = localStorage.getItem('portfolioListDisplayMode')
    if (mode) {
      this.$store.dispatch('ui/setPortfolioListDisplayMode', mode)
    }
  },

  methods: {
    ...mapActions({
      deleteBuildings: 'building/deleteBuildings',
      exportPortfolioBuildings: 'portfolio/exportPortfolioBuildings',
    }),

    //
    async onDeleteAllBuildings() {
      this.pending = true
      const confirmationText = this.$t('deleteConfirmation')
      if (confirm(confirmationText)) {
        try {
          this.error = null
          await this.deleteBuildings({
            buildingIds: this.buildings.map((building) => building.id),
            portfolioId: this.portfolio.id,
          })
        } catch (error) {
          this.error = error
        }
      }
      this.pending = false
    },

    //
    onImportBuilding() {
      this.importModalOpen = true
    },

    //
    onImportBuildingClose() {
      this.importModalOpen = false
    },

    // Show cards or line items
    setDisplayMode(mode) {
      this.$store.dispatch('ui/setPortfolioListDisplayMode', mode)
    },
  },
}
</script>

<style lang="scss">
.c-portfolio-list {
  & .portfolio-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-s) 0;
    margin-bottom: var(--spacing-s);

    & .portfolio-summary {
      display: flex;
      gap: var(--spacing-xxs);

      & .portfolio-summary-info-box {
        position: relative;
        top: -2px;
      }
    }
  }

  & .portfolio-controls__sorting {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-s);

    & .export-spinner {
      width: 24px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & > .portfolio-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    gap: var(--spacing-m);

    & > li {
      /* With side panel */
      width: calc((100% / 5) - (var(--spacing-m) * 5 / 6));

      @media screen and (max-width: 2160px) {
        width: calc((100% / 4) - (var(--spacing-m) * 4 / 5));
      }

      @media screen and (max-width: 1760px) {
        width: calc((100% / 3) - (var(--spacing-m) * 3 / 4));
      }

      @media screen and (max-width: 1439px) {
        width: calc((100% / 2) - (var(--spacing-m) * 2 / 3));
      }

      /* No side panel */
      @media screen and (max-width: 1280px) {
        width: calc((100% / 3) - (var(--spacing-m) * 2 / 3));
      }

      @media screen and (max-width: 960px) {
        width: calc((100% / 2) - (var(--spacing-m) / 2));
      }

      @media screen and (max-width: 640px) {
        width: 100%;
      }
    }
  }

  & > ul {
    &.portfolio-list--table {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 0;
      width: 100%;
      border-radius: var(--box-radius);
      border: var(--box-border);
      overflow: auto;
      max-height: 65svh;
      background-color: #fff;

      @media screen and (max-width: 1440px) {
        max-height: 45svh;
      }

      @media screen and (max-width: 1280px) {
        max-height: 65svh;
      }

      & .portfolio-list__table-header {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fff;
        min-width: fit-content;
        width: 100%;
        display: flex;
        flex-direction: row;
        border-bottom: var(--box-border);

        & * {
          font-size: 13px;
        }

        & > * {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 500;
          letter-spacing: -0.01em;
          gap: var(--spacing-s);
          padding: var(--spacing-xs) var(--spacing-s);
        }

        & .portfolio-list-item-queue {
          flex: none;
          width: 144px;
        }

        & .building-description {
          flex: none;
          width: 20svw;
          min-width: 280px;

          & > * {
            flex: 1;
          }

          & .address {
            flex: 2;
          }
        }

        & .footer {
          flex: 1;
          display: flex;
          flex-direction: row;
          gap: var(--spacing-m);
          padding: var(--spacing-s) 0;

          & .building-kpis {
            flex: 0.5;
            display: flex;
            flex-direction: row;
            gap: var(--spacing-s);
            min-width: 280px;

            & > * {
              flex: 1;
            }
          }

          & .building-attributes {
            flex: 1;
            display: flex;
            flex-direction: row;
            gap: var(--spacing-s);
            min-width: 360px;

            & > * {
              flex: 1;
            }

            & .usage,
            & .heating-type {
              flex: 2;
            }
          }
        }

        & .rings {
          flex: none;
          width: 96px;
          padding: var(--spacing-xs) var(--spacing-s) var(--spacing-xs) var(--spacing-xs);
        }
      }

      & > li {
        width: 100%;
        min-width: fit-content;

        & > a {
          width: 100%;
          min-width: fit-content;

          & main {
            width: 100%;
            min-width: fit-content;

            & > * {
              flex: none;
            }
          }
        }
      }
    }
  }
}
</style>
